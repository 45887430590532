import moment from 'moment';


const domains = {
    slotauto: `https://bo.slotautospin.com/api`,
    igamesea: `https://bo.igamesea.com/api`,
    lionbet: `https://ag.168lionbet.com/api`,
    phenix295: `https://office.phenix295.com/api`,
    phenix168: `https://office.phenix168.com/api`,
    ufo98: `https://office.ufo89bet.com/api`,
    vipaclub: `https://office.vipaclub88.com/api`,
    octa8888: `https://office.octa8888.com/api`,
    lion168: `https://office.168lionx.com/api`,
    sg168: `https://office.sgaming168.com/api`,
    axe888: `https://office.axe888.com/api`,
    local: `http://127.0.0.1:5800`,
}
const logos = {
    slotauto: `logo.png`,
    igamesea: `logo.png`,
    lionbet: `logo-168.png`,
    phenix295: `logo.png`,
    phenix168: `logo-phenix168.png`,
    ufo98: `logo-ufo.png`,
    vipaclub: `logo-vipaclub.png`,
    octa8888: `logo-octa8888.png`,
    lion168: `logo-lion168.png`,
    sg168: `logo-sg168.png`,
    axe888: `logo-axe888.png`,
}


export const logo = logos.axe888
export const domain = domains.axe888
// export const domain = `https://bo.igamesea.com/api`;
// export const domain = `https://bo.igamesea.com/api`;
// export const domain = `https://ag.168lionbet.com/api`;
// export const domain = `https://office.phenix295.com/api`;
export const AUTHKEY = "AUTH";

export const initConfigInputTime = {
    "showDropdowns": true,
    "timePicker": true,
    "timePicker24Hour": true,
    "autoApply": true,
    showCustomRangeLabel: false,
    parentEl: ".modal-body",
    // "autoUpdateInput": true,
    ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days').format("YYYY-MM-DD HH:mm"), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    "alwaysShowCalendars": true,
    "startDate": moment(),
    "endDate": moment(),
}

export const initConfigInputLastMonth = {
    "showDropdowns": true,
    "timePicker": true,
    "timePicker24Hour": true,
    "autoApply": true,
    showCustomRangeLabel: false,
    parentEl: ".modal-body",
    // "autoUpdateInput": true,
    ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    "alwaysShowCalendars": true,
    "startDate": moment().subtract('months', 1),
    "endDate": moment(),
}
